<template>
  <div>
    <modal-pdf :title="title" :pdf-url="pdfUrl" />
    <b-overlay
      variant="white"
      :show="loading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
   
      <modal-sign-link
        :signLinkData = "signLinkData"
      ></modal-sign-link>

      <b-card no-body class="mb-0">
        <div class="m-2">
          <!-- Table Top -->

          <div
            class="d-flex flex-wrap justify-content-between flex-lg-nowrap align-items-center"
          >
            <div class="d-flex">
              <h3
                class="d-flex align-items-center font-weight-bolder text-nowrap mb-0"
              >
                <feather-icon class="mr-50" size="18" icon="FileTextIcon" />
                合同管理
              </h3>
              <div class="form-col-select ml-2">
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block"
                />
                <feather-icon size="18" icon="ChevronDownIcon" />
              </div>
            </div>
            <div class="d-flex">
              <div class="row m-0 top-button-list">
                <div class="col-sm-12 col-md-3">
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    placeholder="搜尋合同"
                  />
                </div>

                <div class="col-sm-12 col-md-3">
                  <b-form-input
                    v-model="idFilter"
                    class="d-inline-block mr-1"
                    placeholder="搜尋身份證號碼"
                  />
                </div>
                <div class="col-sm-12 col-md-3">
                  <flat-pickr
                    id="account_date"
                    v-model="dateFilter"
                    class="form-control"
                    :config="{
                      altInput: true,
                      altFormat: 'Y-m-d',
                      allowInput: true,
                      dateFormat: 'Y-m-d',
                      maxDate: $store.state.app.maxDate,
                    }"
                    placeholder="輸入貸款日期"
                  />
                </div>

                <div class="col-sm-12 col-md-3">
                  <b-button
                    variant="primary"
                    class="mobile-w100 mt-2 mt-sm-0"
                    :to="{ name: 'contracts-create' }"
                  >
                    <span class="text-nowrap">新增合同</span>
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <b-table
          ref="refContractListTable"
          class="position-relative"
          :items="fetchContracts"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="未找到匹配的記錄"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(contract_number)="data">
            <b-link
              class="color1"
              v-if="ability.can('update', 'contracts') || true"
              :to="{ name: 'contracts-edit', params: { id: data.item.id } }"
            >
              {{ data.item.contract_number }}
            </b-link>
          </template>

          <template #cell(id_number)="data">
            <span
              class="id-number color1"
              @click="
                setIdNumber(data.item.client && data.item.client.id_number)
              "
              >{{ data.item.client && data.item.client.id_number }}</span
            >
          </template>

          <!-- Column: is_monthly -->
          <template #cell(is_monthly)="data">
            {{ data.item.is_monthly ? "月供" : "半月供" }}
          </template>

          <!-- Column: is_signed -->
          <template #cell(is_signed)="data">
            <feather-icon
              v-if="data.item.is_signed"
              icon="CheckIcon"
              size="18"
              class="mr-50 text-success"
            />
          </template>

          <template #cell(actions)="data">
            <b-dropdown
              :class="`action-trigger-btn ${
                contractsList.length <= 3 ? 'hor' : 'ver'
              }`"
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="EyeIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <!-- <b-dropdown-item
            :to="{ name: 'contracts-detail', params: { id: data.item.id } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">詳細資料</span>
            </b-dropdown-item> -->

              <b-dropdown-item
                v-if="ability.can('update', 'contracts') || true"
                :to="{ name: 'contracts-edit', params: { id: data.item.id } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">修改資料</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="ability.can('update', 'contracts') || true"
                :to="{ name: 'contracts-copy', params: { id: data.item.id } }"
              >
                <feather-icon icon="CopyIcon" />
                <span class="align-middle ml-50">複製</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="(ability.can('update', 'contracts') || true) && !data.item.is_signed"
                :to="{ name: 'contracts-schedule-edit', params: { id: data.item.id } }"
              >
                <feather-icon icon="ListIcon" />
                <span class="align-middle ml-50">修改供款表</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="(ability.can('read', 'contracts') || true) &&
                  !data.item.is_signed"
                @click="createSignLink(data.item.id)"
              >
                <font-awesome-icon icon="fa-solid fa-link" />
                <span class=" ml-50">生成連結</span>
              </b-dropdown-item>

              <b-dropdown-item
              v-if="(ability.can('download', 'contracts') || true) && !data.item.is_signed"
                @click="downloadContract(data.item.id)"
              >
                <feather-icon icon="DownloadIcon" />
                <span class="align-middle ml-50">下載</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="(ability.can('download', 'contracts') || true) && data.item.is_signed"
                @click="downloadSignedContract(data.item.id)"
              >
                <feather-icon icon="DownloadIcon" />
                <span class="align-middle ml-50"> 下載已簽署的合約</span>
              </b-dropdown-item>
             
              <b-dropdown-item
                v-if="(ability.can('delete', 'contracts') || true) && data.item.is_signed"
                @click="unSign(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" />
                <span class="align-middle ml-50"> 取消簽署 </span>
              </b-dropdown-item> 

              <!-- <b-dropdown-item
                v-if="(ability.can('delete', 'contracts') || true)"
                @click="removeItem(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" />
                <span class="align-middle ml-50"> 刪除 </span>
              </b-dropdown-item>  -->
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >在{{ dataMeta.of }}個記錄中，正在顯示 {{ dataMeta.from }} 至
                {{ dataMeta.to }}個記錄</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalContracts"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>
    
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import useContractsList from "./useContractsList";
import contractStoreModule from "../contractStoreModule";
import ModalPdf from "./ModalPdf.vue";
import flatPickr from "vue-flatpickr-component";
import ModalSignLink from "../modal/ModalSignLink.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ModalPdf,
    BOverlay,
    flatPickr,
    ModalSignLink,
  },
  data() {
    return {
      contractData: {},
      title: "",
      pdfUrl: "",
      signLinkData: {
        link: "",
        contract_number: "",
        name_cn: "",
        name_en: "",
        pw: "",
        msg: "",
      },
    };
  },
  methods: {
    createSignLink(id) {
      this.loading = true;
      this.$store
        .dispatch("app-contract/createSignLink", { client_loan_id: id })
        .then((response) => {
          this.loading = false;

          this.signLinkData.link = response.data.link;
          this.signLinkData.contract_number = response.data.contract_number;
          this.signLinkData.name_cn = response.data.name_cn;
          this.signLinkData.name_en = response.data.name_en;
          this.signLinkData.pw = response.data.password;
          this.signLinkData.msg = response.data.message;

          this.$bvModal.show("modal-sign-link");
        })
        .catch((error) => {
          this.loading = false;
          this.$swal({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            confirmButtonText: "確定",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
    setIdNumber(id) {
      if (this.idFilter == id) {
        this.idFilter = null;
      } else {
        this.idFilter = id;
      }
    },
    downloadContract(id) {
      this.loading = true;
      this.$store
        .dispatch("app-contract/exportContract", { id })
        .then((response) => {
          this.loading = false;
          const filepdf = new Blob([response.data], {
            type: "application/pdf",
          });
          const fileURL = URL.createObjectURL(filepdf);
          this.pdfUrl = fileURL;
          this.$bvModal.show("modal-pdf");
          // console.log(this.pdfUrl)
        })
        .catch((error) => {
          this.loading = false;
          console.log({ error });
        });
    },
    downloadSignedContract(id) {
      this.loading = true;
      this.$store
        .dispatch("app-contract/exportSignedContract", { id })
        .then((response) => {
          this.loading = false;
          const filepdf = new Blob([response.data], {
            type: "application/pdf",
          });
          const fileURL = URL.createObjectURL(filepdf);
          this.pdfUrl = fileURL;
          this.$bvModal.show("modal-pdf");
          // console.log(this.pdfUrl)
        })
        .catch((error) => {
          this.loading = false;
          console.log({ error });
        });
    },
    removeItem(id) {
      // check number of record
      this.$swal({
        title: "你確定嗎?",
        text: "您的決定是最終決定，您將無法取回這記錄。",
        showCancelButton: true,
        confirmButtonText: "刪除",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("app-contract/deleteClientContract", { id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    unSign(id) {
      // check number of record
      this.$swal({
        title: "你確定嗎?",
        text: "您的決定是最終決定，您將無法取回這簽署。",
        showCancelButton: true,
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("app-contract/unSign", { id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-contract";
    const loading = ref(false);
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, contractStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const {
      fetchContracts,
      tableColumns,
      perPage,
      currentPage,
      totalContracts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refContractListTable,
      refetchData,
      contractsList,
      ability,
      idFilter,
      dateFilter,
    } = useContractsList();

    return {
      fetchContracts,
      tableColumns,
      perPage,
      currentPage,
      totalContracts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refContractListTable,
      refetchData,
      contractsList,
      ability,
      idFilter,
      dateFilter,
      loading,
    };
  },
};
</script>
    
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.search-primary {
  width: calc(100% - 500px);
}
.top-button-list {
  // width: 500px;
  margin: 0;
  .btn {
    width: 150px;
    margin: 0 auto;
    display: block;
  }
}
@media screen and (max-width: 991px) {
  .search-primary {
    width: calc(100% - 220px);
  }
  .top-button-list {
    margin-left: auto;
    margin-right: 20px;
    .btn {
      width: 150px;
      margin: 0 auto;
      display: block;
    }
  }
}

@media screen and (max-width: 768px) {
  .search-primary {
    width: 100%;
  }
}
@media screen and (max-width: 575px) {
  .top-button-list {
    margin: 0;
    width: 100%;
  }
}
</style>
    
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.id-number {
  // text-decoration: underline;
  cursor: pointer;
}
</style>
    