<template>
  <!-- modal Add Coin-->
    <b-modal
      id="modal-sign-link"
      hide-footer
      cancel-variant="outline-secondary"
      centered
      :title="signLinkData.msg"
      :ok-disabled="true"
      size="md"
    >
      <div class="p-2">
        <h5 class="d-flex mb-1">
          <b class="text-nowrap">合約編號：</b>
          <span>{{signLinkData.contract_number}}</span>
        </h5>
        <h5 class="d-flex mb-1">
          <b class="text-nowrap">客戶姓名：</b>
          <span>{{signLinkData.name_cn || signLinkData.name_en}}</span>
        </h5>
        <h5 class="d-flex mb-1">
          <b class="text-nowrap">連結：</b>
          <span style="word-break: break-all;">{{signLinkData.link}}</span>
        </h5>  
        <h5 class="d-flex mb-1">
          <b class="text-nowrap">密碼：</b>
          <span>{{signLinkData.pw}}</span>
        </h5>
       
        <div class="d-flex justify-content-end mt-2">
          <b-button class="" @click="copy" >
             復制文字
          </b-button>
        </div>
      </div>

        
    </b-modal>
</template>

<script>
import Ripple from "vue-ripple-directive";
import store from "@/store";
import {
  BModal,
  BButton,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BOverlay,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    };
  },
  props: {
    signLinkData:{}
  },
  methods: {
    hideModal() {
      this.$root.$emit("bv::hide::modal", "modal-sign-link", "#btnShow");
    },
    copy(){
      this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `成功`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: "復制成功",
              },
            })
      const text = 
`合約編號：${this.signLinkData.contract_number}
客戶姓名：${this.signLinkData.name_cn || this.signLinkData.name_en}
連結：${this.signLinkData.link}
密碼：${this.signLinkData.pw}
${this.signLinkData.msg}`
     
      navigator.clipboard.writeText(text)
    }
  },
};
</script>

<style></style>
